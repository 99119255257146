import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { motion as m } from 'framer-motion';

import MapExercise from './MapExercise';

const AddExercises = (props) => {
    const { exercises, addExercise, removeExercise, switchType, setExercises } = props;

    const handleSwitchType = (event) => {
        let value = event.split('-')[0];
        let index = event.split('-')[1];
        switchType({ index, value });
    }

    const handleChangeExerciseName = (event) => {
        const value = event.target.value;
        const index = event.target.name;
        let newExercises = [...exercises]
        newExercises[index].name = value;
        setExercises(newExercises);
    }

    const handleChangeExerciseReps = (event) => {
        let index = event.target.name.split('~')[1]
        let value = event.target.value;
        let newExercises = [...exercises]
        newExercises[index].reps = value;
        setExercises(newExercises);
    }

    const handleChangeExerciseTime = (event) => {
        let index = event.target.name.split('~')[1]
        let value = event.target.value;
        let newExercises = [...exercises]
        newExercises[index].time = value;
        setExercises(newExercises);
    }

    const holderFunction = () => {
        setExercises(exercises)
    }

    const getTitle = (value) => {
        if (value === 'CV') {
            return 'Cardio'
        } else if (value === 'Weights') {
            return 'Lift'
        } else {
            return 'Time'
        }
    }

    const handleChangeDistance = (event) => {
        let index = event.target.name.split('~')[1]
        let value = event.target.value;
        let newExercises = [...exercises]
        newExercises[index].distance = value;
        setExercises(newExercises);
    }

    return (
        <m.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ duration: 0.75, ease: 'easeOut' }}>
            <p>Please add the desired exercises to the event:</p>
            <Row style={{ marginBottom: '20px', color: '#26ffe2' }}>
                <Col xs={5}>Exercise</Col>
                <Col xs={2}>Type</Col>
                <Col xs={2}>VIRTUAL (km)</Col>
                <Col xs={1}>LIVE</Col>
            </Row>
            {exercises?.length > 0 && exercises.map((exercise, index) => {
                let slug = exercise.name.toLowerCase().replace(/ /g, '_');
                return <MapExercise key={slug + '-' + index}
                    index={index}
                    exercise={exercise}
                    handleChangeExerciseName={handleChangeExerciseName}
                    holderFunction={holderFunction}
                    getTitle={getTitle}
                    handleSwitchType={handleSwitchType}
                    handleChangeExerciseReps={handleChangeExerciseReps}
                    handleChangeDistance={handleChangeDistance}
                    handleChangeExerciseTime={handleChangeExerciseTime}
                    removeExercise={removeExercise}
                />
            })}
            <button className="green-glow-button" onClick={addExercise} variant="info" style={{ width: '100%', marginTop: '20px' }}>
                <FontAwesomeIcon icon={faPlus} /> Add New Exercise
            </button>
        </m.div>
    )
}

export default AddExercises