import React, { useContext, useEffect, useState } from 'react';
import MapEventBlocks from './MapEventBlocks';
import { Context } from '../../../../lib/Context';
import { motion as m } from 'framer-motion'


const MapEventContent = (props) => {
    const { events } = useContext(Context);
    const { event, index, eventType, getCompetitors } = props;
    const eventID = event._id;

    const [isLive, setIsLive] = useState(false);

    useEffect(() => {
        for (let i = 0; i < events.length; i++) {
            if (events[i]._id === eventID) {
                setIsLive(events[i].isLive || false);
            }
        }
    }, [eventID, events])

    return (
        <m.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ duration: 0.75, ease: 'easeOut' }}>
            <MapEventBlocks index={index} event={event} eventID={eventID} isLive={isLive} eventType={eventType}
                getCompetitors={getCompetitors} />
        </m.div>
    )
}

export default MapEventContent