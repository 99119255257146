import React, { useContext, useState } from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/free-solid-svg-icons';
import { Context } from '../../lib/Context';
import SidebarNav from './SidebarNav';
import '../../lib/css/NavBar.css';
// eslint-disable-next-line
import Alert from 'react-bootstrap/Alert';

import logo from '../../lib/Images/logo.png'


const NavBar = (props) => {
    // eslint-disable-next-line
    const { setViewPage, emailValidated, email, setIsAdjudicator } = useContext(Context);
    const [showSidebar, setShowSidebar] = useState(false);
    const { setShowAccount } = props;

    return (
        <>
            <div className="topnav" style={{
                backgroundColor: '#070039', padding: '0 10px 0 10px', color: '#26ffe1'
            }}>
                <Link to="/" onClick={() => {
                    setViewPage('home-page')
                    setShowSidebar(false);
                    setIsAdjudicator(false);
                }}>
                    <img src={logo} style={{ height: '40px', width: 'auto', marginTop: '7px' }} alt="Description" />
                </Link>
                {/* {email ?
                    <span onClick={logout}><FontAwesomeIcon icon={faRightFromBracket} /> Logout</span>
                    :
                    // <span onClick={() => { setShowAuth(true) }}><FontAwesomeIcon icon={faRightToBracket} /> Login</span>
                    <></>
                }
                {email &&
                    <Link to="/events" >
                        <span className={(viewPage === 'events-page') ? "active" : "none"}
                            onClick={() => { setViewPage('events-page') }}>
                            <FontAwesomeIcon icon={faMedal} /> Events
                        </span>
                    </Link>
                }
                {type !== 'admin' && email &&
                    <Link>
                        <span className={"none"}
                            onClick={() => { setShowEditProfileModal(true) }}>
                            <FontAwesomeIcon icon={faUser} /> Profile
                        </span>
                    </Link>
                } */}
                <span
                    className="no-background-hover"
                    onClick={() => setShowSidebar(true)}
                >
                    <FontAwesomeIcon icon={faBars} />
                </span>
            </div>
            {/* <Button variant="secondary" onClick={() => { setShowSidebar(true) }}>
                <FontAwesomeIcon icon={faBars} />
            </Button> */}
            <SidebarNav
                setShowAccount={setShowAccount}
                showSidebar={showSidebar} setShowSidebar={setShowSidebar}
            />
            {/* {!emailValidated && email &&
                <Alert variant="info" style={{ textAlign: 'center', marginBottom: '40px', padding: '10px' }}>
                    Please <strong>activate your account</strong> by clicking the link in the email we sent you 🚀
                </Alert>
            } */}
        </>
    );
}

export default NavBar