import React, { useContext, useEffect, useState } from 'react';
import { Context } from '../../../lib/Context';
import InvisibleInput from '../PageElements/InvisibleInput';
import user_icon from '../../../lib/Images/user_icon.png';
import '../../../lib/css/EventCompetitor.css';
import Spinner from 'react-bootstrap/Spinner';
import SwitchEventAdjudicator from './EventElements/SwitchEventAdjudicator';

const EventCompetitor = (props) => {
    let {
        competitorObject, adjudicators, index, competitors, isLoading, setIsLoading,
        getCompetitors, eventID
    } = props;
    const {
        API_LINK, type, setShowAddCompetitorScoreModal, setAddCompetitorScoreEventID,
        setAddCompetitorScoreCompetitorObject, event, setEvents, isLiveEvent
    } = useContext(Context);
    // eslint-disable-next-line
    let [adjudicator, setAdjudicator] = useState({});
    let [eventScores, setEventScores] = useState({});
    let [total, setTotal] = useState(0);
    let [time, setTime] = useState(0);
    let [competitorID, setCompetitorID] = useState('');
    let [adjudicatorID, setAdjudicatorID] = useState('');

    let userType = type;

    useEffect(() => {
        // Get the adjudicator ID
        setEventScores({ ...competitorObject.exercise_scores });
        setTotal(competitorObject.total);

        setTime(competitorObject.exercise_scores['time']);
        setCompetitorID(competitorObject.userID);

        for (let i = 0; i < event?.competitors.length; i++) {
            if (event?.competitors[i].userID === competitorObject._id) {
                let adjudicatorID = event?.competitors[i].adjudicator.userID;
                // Find the competitor and set the relevant adjudicator to the wave
                for (let j = 0; j < adjudicators.length; j++) {
                    if (adjudicators[j]._id === adjudicatorID) {
                        setAdjudicator(adjudicators[j]);
                        setAdjudicatorID(adjudicators[j].userID);
                    }
                }
            }
        }

    }, [competitorObject._id, event.competitors, adjudicators, event.exercises, competitorObject]);

    const updateCompetitorScore = async () => {
        const scores = { ...eventScores }
        scores['time'] = time
        setIsLoading(true);
        const response = await fetch(API_LINK + '/update-competitor-score', {
            method: 'POST',
            body: JSON.stringify({
                competitorID: competitorObject._id, exercise_scores: scores, eventID: event._id, competitors,
                isLiveEvent
            }),
            headers: { 'Content-Type': 'application/json' },
            credentials: 'include'
        })
        if (response.ok) {
            response.json().then(async data => {
                setEvents(data.events);
                getCompetitors(event._id);
            });
        }
    }

    const handleChangeScore = (event) => {
        let name = event.target.name;
        let value = event.target.value;

        // Only parse the value if it's not empty
        if (value !== '') {
            value = parseInt(value, 10);
            if (isNaN(value)) {
                value = 0; // Default to zero if parsing fails
            }
        } else {
            value = ''; // Keep empty string if input is empty
        }

        let newMap = { ...eventScores };
        newMap[name] = value;
        setEventScores(newMap);
    }

    const handleShowAddCompetitorScoresModal = () => {
        setAddCompetitorScoreEventID(event._id);
        setAddCompetitorScoreCompetitorObject(competitorObject);
        setShowAddCompetitorScoreModal(true);
    }

    const handleChangeTime = (event) => {
        let value = event.target.value;
        // Remove leading zeros
        if (value.length > 1 && value.startsWith('0')) {
            value = value.replace(/^0+/, '');
        }
        // Format the time as per the required mm:ss.sss format
        // const formattedTime = formatTime(value);
        setTime(value.replace(/[^0-9]/g, ''));
    }

    const formatTime = (value) => {
        // Convert value to a string in case it's a number or something else
        if (typeof value !== 'string') {
            value = String(value);
        }

        // Remove all non-numeric characters
        value = value.replace(/[^0-9]/g, '');

        // Insert colon after 2 digits for minutes
        if (value.length > 2) {
            value = value.slice(0, 2) + ':' + value.slice(2);
        }

        // Insert period after seconds, if input length is greater than 5
        if (value.length > 5) {
            value = value.slice(0, 5) + '.' + value.slice(5);
        }

        return value;
    };

    // eslint-disable-next-line
    const updateCompetitorAdjudicator = async () => {
        const response = await fetch(API_LINK + '/update-competitor-adjudicator', {
            method: 'POST',
            body: JSON.stringify({ eventID, competitorID, adjudicatorID }),
            headers: { 'Content-Type': 'application/json' },
            credentials: 'include'
        })
        if (response.ok) {
            response.json().then(async data => {
                // Do stuff here
            });
        }
    }

    return (
        <tr>
            <td>
                <div onClick={handleShowAddCompetitorScoresModal} style={{ cursor: 'pointer', color: 'white', fontWeight: 'bold' }}>
                    <table>
                        <tbody>
                            <tr>
                                <td style={{ width: '45px' }}>
                                    <img src={user_icon} alt="icon" className="competitor-icon" />
                                </td>
                                <td style={{ paddingTop: '0px' }}>
                                    {competitorObject?.firstname} {competitorObject.lastname} <br />
                                    <div style={{ color: '#6c757d', marginTop: '-10px' }}>
                                        #{competitorObject.tag_number}
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </td>
            {isLiveEvent &&
                <td style={{ paddingRight: '40px' }}>
                    {type === 'admin' ?
                        <SwitchEventAdjudicator event={event} competitorObject={competitorObject} index={index}
                            getCompetitors={getCompetitors} />
                        :
                        adjudicator?.firstname && adjudicator?.firstname.charAt(0) + '.' + adjudicator.lastname
                    }
                </td>
            }
            {event.exercises.map((exercise) => {
                let type = exercise.type;
                let slug = exercise.name.toLowerCase().replace(/\s+/g, '_');
                if (type === 'Weights') {
                    return (
                        <React.Fragment key={event._id + '-' + slug + '-' + index + '-' + competitorObject._id}>
                            <td style={{ fontSize: '0.8em' }}>
                                {isLiveEvent && event.competitors.map((competitor) => {
                                    if (competitor.userID === competitorObject._id) {
                                        return <span style={{ color: '#26ffe2' }}>
                                            {competitor.exercise_weights[slug] + 'kg'}
                                        </span>
                                    } else {
                                        return '';
                                    }
                                })}
                            </td>
                            <td style={{
                                color: eventScores[slug] !== 0 && eventScores[slug] && 'white',
                                fontSize: '1.4em'
                            }}>
                                {userType === 'admin' && !isLoading ?
                                    <InvisibleInput
                                        value={eventScores[slug] || 0}
                                        onInput={handleChangeScore}
                                        onBlur={updateCompetitorScore}
                                        name={slug} type={"text"}
                                        weight={'bold'}
                                        disabled={false}
                                    />
                                    :
                                    <InvisibleInput
                                        value={eventScores[slug] || 0}
                                        onInput={handleChangeScore}
                                        onBlur={updateCompetitorScore}
                                        name={slug} type={"text"}
                                        weight={'bold'}
                                        disabled={true}
                                    />
                                }

                            </td>
                        </React.Fragment>
                    )
                } else if (type === 'CV') {
                    return (
                        <td key={slug + '-' + index + '-' + competitorObject._id} style={{
                            color: eventScores[slug] !== 0 && eventScores[slug] && 'white',
                            fontSize: '1.4em'
                        }}>
                            {userType === 'admin' && !isLoading ?
                                <InvisibleInput
                                    value={eventScores[slug] || 0}
                                    onInput={handleChangeScore}
                                    onBlur={updateCompetitorScore} // Use handleBlur here
                                    name={slug}
                                    type={"text"}
                                    weight={'bold'}
                                    disabled={false}
                                />
                                :
                                <InvisibleInput
                                    value={eventScores[slug] || 0}
                                    onInput={handleChangeScore}
                                    onBlur={updateCompetitorScore} // Use handleBlur here
                                    name={slug}
                                    type={"text"}
                                    weight={'bold'}
                                    disabled={true}
                                />
                            }
                        </td>
                    )
                } else {
                    return isLiveEvent && <td key={slug + '-' + index + '-' + competitorObject._id} style={{
                        color: eventScores[slug] !== 0 && eventScores[slug] && 'white',
                        fontSize: '1.4em'
                    }}>
                        <InvisibleInput
                            value={formatTime(time) || 0}
                            onInput={handleChangeTime}
                            onBlur={updateCompetitorScore} // Use handleBlur here
                            name={slug}
                            type={"text"}
                            weight={'bold'}
                            disabled={false}
                        />
                    </td>
                }
            })}
            <td style={{ textAlign: 'center', color: 'white', fontWeight: 'bold', fontSize: '1.8em' }}>
                {isLoading ?
                    <Spinner animation="border" size="sm" />
                    :
                    total || 0
                }
            </td>
        </tr>
    )
}

export default EventCompetitor