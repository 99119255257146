import React from 'react';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/esm/Col';
import Row from 'react-bootstrap/esm/Row';
import DatePicker from "react-datepicker";
import { Dropdown, DropdownButton } from 'react-bootstrap';
import '../../../lib/css/CompulsoryProfileInfo.css';

const CompulsoryProfileInfo = (props) => {
    const { dob, setDob, gender, firstname, lastname, phoneNumber, deliveryAddress, deliveryAddressTwo,
        postcode, city, handleChangeValue, handleSelectGender
    } = props;

    console.log(dob);

    return (
        <>
            <Row style={{ marginBottom: '15px' }}>
                <Col>
                    <div>Gender <span style={{ color: '#26ffe2' }}>*</span></div>
                    <DropdownButton
                        id="custom-dropdown"
                        title={gender || 'Select'}
                        onSelect={handleSelectGender}
                        variant="info"
                        className={(gender === 'Male' || gender === 'Female') ? "full-width-dropdown" : "full-width-dropdown missing-field"}
                    >
                        <Dropdown.Item eventKey="Male">Male</Dropdown.Item>
                        <Dropdown.Item eventKey="Female">Female</Dropdown.Item>
                    </DropdownButton>
                </Col>
            </Row>
            <Row style={{ marginBottom: '20px' }}>
                <Col>
                    <div>Firstname <span style={{ color: '#26ffe2' }}>*</span></div>
                    <Form autoComplete="off" >
                        <Form.Control type="text" placeholder="Firstname" value={firstname} name="firstname"
                            onInput={handleChangeValue} className={firstname?.length > 0 ? '' : 'missing-field'} />
                    </Form>
                </Col>
                <Col>
                    <div>Lastname <span style={{ color: '#26ffe2' }}>*</span></div>
                    <Form autoComplete="off" >
                        <Form.Control type="text" placeholder="Lastname" value={lastname} name="lastname"
                            onInput={handleChangeValue} className={lastname?.length > 0 ? '' : 'missing-field'} />
                    </Form>
                </Col>
            </Row>
            <Row style={{ marginBottom: '20px' }}>
                <Col>
                    <div>Phone Number <span style={{ color: '#26ffe2' }}>*</span></div>
                    <Form autoComplete="off" >
                        <Form.Control type="text" placeholder="Phone Number" value={phoneNumber} name="phone-number"
                            onInput={handleChangeValue} className={phoneNumber?.length > 0 ? '' : 'missing-field'} />
                    </Form>
                </Col>
                <Col>
                    <Col>
                        <div style={{ marginBottom: '-15px' }}>D.O.B <span style={{ color: '#26ffe2' }}>*</span></div>
                        <DatePicker
                            selected={dob || null}
                            onChange={(date) => setDob(date)}
                            className="form-control"
                            dateFormat="dd/MM/yyyy"
                            id={!dob ? "profile-datePicker" : 'start-datePicker'}
                            peekNextMonth
                            showMonthDropdown
                            showYearDropdown
                            dropdownMode="select"
                            style={{ border: '1px solid red' }}
                        />
                    </Col>
                </Col>
            </Row>
            <Row style={{ marginBottom: '20px' }}>
                <Col>
                    <div>Delivery address <span style={{ color: '#26ffe2' }}>*</span></div>
                    <Form autoComplete="off" >
                        <Form.Control type="text" placeholder="Delivery Address" value={deliveryAddress} name="delivery-address"
                            onInput={handleChangeValue} className={deliveryAddress?.length > 0 ? '' : 'missing-field'} />
                    </Form>
                </Col>
                <Col xs={4}>
                    <div>Postcode <span style={{ color: '#26ffe2' }}>*</span></div>
                    <Form autoComplete="off" >
                        <Form.Control type="text" placeholder="Postcode" value={postcode} name="postcode" autoComplete="off"
                            onInput={handleChangeValue} className={postcode?.length > 0 ? '' : 'missing-field'} />
                    </Form>
                </Col>
            </Row>
            <Row style={{ marginBottom: '20px' }}>
                <Col>
                    <div>Delivery address 2 <span style={{ color: '#26ffe2' }}>*</span></div>
                    <Form autoComplete="off" >
                        <Form.Control type="text" placeholder="Delivery Address 2"
                            value={deliveryAddressTwo} name="delivery-address-two"
                            onInput={handleChangeValue} className={deliveryAddressTwo?.length > 0 ? '' : 'missing-field'} />
                    </Form>
                </Col>
                <Col xs={4}>
                    <div>City <span style={{ color: '#26ffe2' }}>*</span></div>
                    <Form autoComplete="off" >
                        <Form.Control type="text" placeholder="City" value={city} name="city"
                            onInput={handleChangeValue} className={city?.length > 0 ? '' : 'missing-field'} />
                    </Form>
                </Col>
            </Row>
        </>
    )
}

export default CompulsoryProfileInfo