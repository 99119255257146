import React, { useContext, useEffect, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import { Context } from '../../lib/Context';
import { Col, Row } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';

const UpdateVirtualEventScoresModal = () => {
    const { API_LINK, showUpdateVirtualScoresModal, setShowUpdateVirtualScoresModal, usersID,
        updateVirtualScoresEventID, setUpdateVirtualScoresEventID, events, setEvents
    } = useContext(Context)
    const [exercises, setExercises] = useState(new Map());
    const [exerciseScores, setExerciseScores] = useState(new Map());

    useEffect(() => {
        if (events) {
            for (let i = 0; i < events.length; i++) {
                if (events[i]._id === updateVirtualScoresEventID) {
                    for (let j = 0; j < events[i].virtual_competitors.length; j++) {
                        if (events[i].virtual_competitors[j].userID === usersID) {
                            setExerciseScores({ ...events[i].virtual_competitors[j].exercise_scores })
                        }
                    }
                    let eventExercises = []
                    for (let j = 0; j < events[i].exercises.length; j++) {
                        eventExercises.push(events[i].exercises[j])
                    }
                    setExercises([...eventExercises]);
                }
            }
        }
    }, [updateVirtualScoresEventID, events, usersID]);

    const handleSetScores = (event) => {
        let slug = event.target.name;
        let score = parseInt(event.target.value);
        let newScores = { ...exerciseScores };
        newScores[slug] = score;
        setExerciseScores(newScores);
    }

    const handleClose = () => {
        setUpdateVirtualScoresEventID('');
        setShowUpdateVirtualScoresModal(false)
    };

    const updateCompetitorScores = async () => {
        const response = await fetch(API_LINK + '/update-virtual-scores', {
            method: 'POST',
            body: JSON.stringify({
                eventID: updateVirtualScoresEventID, userID: usersID, scores: exerciseScores
            }),
            headers: { 'Content-Type': 'application/json' },
            credentials: 'include'
        })
        if (response.ok) {
            response.json().then(async data => {
                setEvents(data.events);
                handleClose();
            });
        }
    }

    return (
        <>
            <Modal show={showUpdateVirtualScoresModal} onHide={handleClose} className="modal-dark">
                <Modal.Header closeButton>
                    <Modal.Title>
                        <span className="glow-text" style={{
                            fontFamily: 'Neon', color: '#26ffe2', fontSize: '2em', lineHeight: '0.6em'
                        }}>
                            Competitor Scores
                        </span>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>
                        Please keep us updated on your progress. Put in your scores below.
                    </p>
                    {exercises?.length > 0 && exercises.map((exercise, index) => {
                        let slug = exercise.name.toLowerCase().replace(/\s+/g, '_')
                        if (exercise.type !== 'Time') {
                            return <Row key={'scores-for-' + slug + updateVirtualScoresEventID}
                                style={{ marginBottom: '20px' }}>
                                <Col style={{ textAlign: 'right', paddingTop: '7px' }} xs={8}>
                                    <h6>{exercise.name}</h6>
                                </Col>
                                <Col xs={4}>
                                    <Form.Control
                                        type="text"
                                        name={slug}
                                        value={exerciseScores[slug] === 0 ? '' : exerciseScores[slug]}
                                        onChange={handleSetScores}
                                        placeholder={exercise.type === 'Weights' ? 'kg' : exercise.type === 'CV' && 'mins:secs'}
                                    />
                                </Col>
                            </Row>
                        } else {
                            return ''
                        }
                    })}
                    <p><i>NOTE: Please be aware that the value for weights is in 'kg' and value for time is in 'mm:ss'.</i></p>
                </Modal.Body>
                <Modal.Footer>
                    <button className="white-glow-button float-left" style={{ float: 'left' }} onClick={handleClose}>
                        Close
                    </button>
                    <button className="green-glow-button" style={{ float: 'left' }} onClick={updateCompetitorScores}>
                        Save Changes
                    </button>
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default UpdateVirtualEventScoresModal