import { useContext, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import { Context } from '../../lib/Context';
import '../../lib/css/AddEventModal.css';
import '../../lib/css/DarkModal.css';
import Form from 'react-bootstrap/Form';
import Papa from 'papaparse';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRetweet } from '@fortawesome/free-solid-svg-icons';
import Spinner from 'react-bootstrap/Spinner';
import { Col, Row } from 'react-bootstrap';


const AddCompetitorsModal = () => {
    const { API_LINK, showAddAdjudicatorModal, setShowAddAdjudicatorModal, addAdjudicatorEventID, setAddAdjudicatorEventID,
        setEvents, setShowAlert, setAlertObject
    } = useContext(Context);
    const [email, setEmail] = useState('');
    const [adjudicators, setAdjudicators] = useState([]);
    const [addMultiple, setAddMultiple] = useState(true);
    const [isLoading, setIsLoading] = useState(false);
    const [firstname, setFirstname] = useState('');
    const [lastname, setLastname] = useState('');

    const handleAddData = (event) => {
        let type = event.target.name;
        let value = event.target.value;
        switch (type) {
            case 'email':
                setEmail(value);
                break;
            case 'firstname':
                setFirstname(value);
                break;
            case 'lastname':
                setLastname(value);
                break;
            default:
                break;
        }
        let adjudicator = [];
        adjudicator.push({ email, firstname, lastname })
        setAdjudicators(adjudicator);
    }

    const addAdjudicator = async () => {
        setIsLoading(true);
        const response = await fetch(API_LINK + '/add-adjudicator', {
            method: 'POST',
            body: JSON.stringify({ eventID: addAdjudicatorEventID, adjudicators }),
            headers: { 'Content-Type': 'application/json' },
            credentials: 'include'
        })
        if (response.ok) {
            response.json().then(async data => {
                if (data.competitorFound) {
                    setShowAlert(true);
                    let alertMessage = {
                        variant: 'danger',
                        text: 'You can\'t add competitors as adjudicators to an event.'
                    }
                    setAlertObject(alertMessage);
                    setIsLoading(false);
                } else {
                    setEmail('');
                    handleClose();
                }
                setEvents(data.events);
            });
        }
    }

    const handleClose = () => {
        setShowAddAdjudicatorModal(false);
        setAddAdjudicatorEventID('');
        setIsLoading(false);
        setEmail('');
        setTimeout(() => {
            setAddMultiple(true);
        }, "200");
    };

    const addFile = (event) => {
        const file = event.target.files[0];
        if (file) {
            Papa.parse(file, {
                header: true, // Assumes the CSV file has headers
                skipEmptyLines: true,
                complete: (result) => {
                    let csvData = [];
                    for (let i = 0; i < result.data.length; i++) {
                        let email = result.data[i].email;
                        let firstname = result.data[i].firstname || ''
                        let lastname = result.data[i].lastname || ''
                        csvData.push({ email, firstname, lastname });
                    }
                    setAdjudicators(csvData); // Set parsed data as JSON
                },
                error: (error) => {
                    console.error("Error parsing the CSV file:", error);
                }
            });
        }
    }

    const toggleAddIndividual = () => {
        setAddMultiple(!addMultiple);
    }

    return (
        <Modal show={showAddAdjudicatorModal} onHide={handleClose} className="modal-dark">
            <Modal.Header closeButton>
                <Modal.Title>Add Adjudicator to Event</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {!addMultiple ?
                    <>
                        <Row>
                            <Col>
                                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                    <Form.Control onChange={handleAddData} onBlur={handleAddData} value={firstname}
                                        type="text" name="firstname" placeholder="Firstname" />
                                </Form.Group>
                            </Col>
                            <Col>
                                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                    <Form.Control onChange={handleAddData} onBlur={handleAddData} value={lastname}
                                        type="text" name="lastname" placeholder="Lastname" />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                    <Form.Control onChange={handleAddData} onBlur={handleAddData} value={email}
                                        type="email" name="email" placeholder="name@example.com" />
                                </Form.Group>
                            </Col>
                        </Row>

                    </>
                    :
                    <>
                        <Form.Group controlId="formFile" className="mb-3">
                            <Form.Control type="file" onChange={addFile} aria-describedby="info-block" />
                        </Form.Group>
                    </>
                }
                <Form.Text id="info-block" muted >
                    <span style={{ color: 'white', marginTop: '10px', fontStyle: 'italic' }}>
                        NOTE: If you try to add a competitor here you will get an error message
                        as you can't register a competitor as an adjudicator{!addMultiple && '.'} {addMultiple &&
                            ' but any non competitors adjudicators you try to add will be added despite the warning.'}
                    </span>

                </Form.Text>
            </Modal.Body>
            <Modal.Footer>
                <div style={{ width: '100%' }}>
                    <button className="white-glow-button float-left" style={{ float: 'left' }} onClick={toggleAddIndividual}>
                        <FontAwesomeIcon icon={faRetweet} /> Add {addMultiple ? 'Single' : 'Multiple'}
                    </button>
                    <button className="green-glow-button float-right" onClick={addAdjudicator} style={{ width: '200px' }}>
                        {isLoading ? <Spinner animation="border" size="sm" /> : `Add Adjudicator${addMultiple ? 's' : ''}`}
                    </button>
                </div>
            </Modal.Footer>
        </Modal>
    );
}

export default AddCompetitorsModal;