import { useContext, useState } from 'react';
import validateEmail from '../../lib/Functions/validateEmail';
import Modal from 'react-bootstrap/Modal';
import Row from 'react-bootstrap/Row';
import Form from 'react-bootstrap/Form';
import Alert from 'react-bootstrap/Alert';
import { Context } from '../../lib/Context';
import '../../lib/css/DarkModal.css';
import { useNavigate } from 'react-router-dom';

function AuthModal(props) {
    const { showAuth, setShowAuth } = props;
    const { API_LINK, setFirstname, setLastname, setEmail, setProfilePicture, setEvents, setType, setUsersID,
        handleCheckUserDetails
    } = useContext(Context);
    const [loginEmail, setLoginEmail] = useState('');
    const [password, setPassword] = useState('');
    const [passwordsMatch, setPasswordsMatch] = useState(false);
    const [confirmPassword, setConfirmPassword] = useState('');
    const [validEmail, setValidEmail] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');

    const [userExists, setUserExists] = useState(false);

    const navigate = useNavigate();

    const login = async () => {
        const response = await fetch(API_LINK + '/login', {
            method: 'POST',
            body: JSON.stringify({ password, email: loginEmail }),
            headers: { 'Content-Type': 'application/json' },
            credentials: 'include'
        })
        if (response.ok) {
            response.json().then(async data => {
                setFirstname(data.firstname);
                setEmail(data.email);
                setLastname(data.lastname);
                setProfilePicture(data.profilePicture);
                setEvents(data.events);
                setType(data.type);
                setUsersID(data.usersID);
                closeModal();
                handleCheckUserDetails({ userID: data.usersID, events: data.events });
                navigate('/events');
                // Check to see if the user is any upcoming events
                // Then display a popup to get them to enter in their weights for the event
            });
        } else {
            setErrorMessage('That doesn\'t seem to be the right password for this email.')
        }
    }

    // This builds the input data from the form inputs.
    const buildInputs = (event) => {
        const value = event.target.value;
        const type = event.target.name;
        switch (type) {
            case 'password':
                setPassword(value);
                confirmPassword === value && confirmPassword.length > 0 ? setPasswordsMatch(true) : setPasswordsMatch(false);
                break;
            case 'confirm-password':
                setConfirmPassword(value);
                password === value && password.length > 0 ? setPasswordsMatch(true) : setPasswordsMatch(false);
                break;
            default:
                break;
        }
    }

    // Checks to see if an email is valid and if it is retrieves the information from the backend.
    const buildEmail = async (event) => {
        setValidEmail(false);
        const userInput = event.target.value.toLowerCase();
        const isValid = validateEmail(userInput);
        const response = await fetch(API_LINK + '/check-users-email', {
            method: 'POST',
            body: JSON.stringify({ email: userInput }),
            headers: { 'Content-Type': 'application/json' },
            credentials: 'include'
        });
        if (response.ok) {
            response.json().then(async data => {
                setUserExists(data);
                setValidEmail(true);
            });
        }
        if (isValid) {

        }
        setLoginEmail(userInput);
    }


    const setNewPassword = async () => {
        const response = await fetch(API_LINK + '/set-new-password', {
            method: 'POST',
            body: JSON.stringify({ password, email: loginEmail }),
            headers: { 'Content-Type': 'application/json' },
            credentials: 'include'
        })
        if (response.ok) {
            response.json().then(async () => {
                await login();
            });
        }
    }

    const closeModal = () => {
        setShowAuth(false);
        setLoginEmail('');
        setPassword('');
        setPasswordsMatch(false);
        setConfirmPassword('');
        setValidEmail(false);
        setErrorMessage('');
    }

    const forgotPassword = () => {
        // TODO: Figure this out
    }

    return (
        <Modal show={showAuth} onHide={closeModal} className="modal-dark">
            <Modal.Header closeButton>
                <Modal.Title>Login or Register</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Row style={{ marginBottom: '30px' }}>
                    <Form>
                        <p>Please enter the email you wish to <u>login</u> or <u>register</u> with.</p>
                        <Form.Group className="mb-3" controlId="login-email">
                            <Form.Control type="email" value={loginEmail} onInput={buildEmail} placeholder="name@example.com" />
                        </Form.Group>
                        {validEmail && !userExists &&
                            <>
                                <p>
                                    Looks like we need to create an account for you. Please enter in your desired password and
                                    once you're done we'll send you an email so that you can verify your email address.
                                </p>
                                <Form.Group className="mb-3" controlId="password">
                                    <Form.Control type="password" name="password" value={password}
                                        onInput={buildInputs} placeholder="Password" />
                                </Form.Group>
                                <Form.Group className="mb-3" controlId="confirm-password">
                                    <Form.Control type="password" name="confirm-password" value={confirmPassword}
                                        onInput={buildInputs} placeholder="Confirm Password" />
                                </Form.Group>
                            </>
                        }
                        {validEmail && userExists &&
                            <>
                                <Form.Group className="mb-3" controlId="login-password">
                                    <Form.Control type="password" name="password" value={password} onInput={buildInputs}
                                        placeholder="Password" />
                                </Form.Group>
                                <p onClick={forgotPassword} style={{
                                    color: '#0D6EFD', width: '100%', textAlign: 'center', cursor: 'pointer'
                                }}>Forgot Password?</p>
                            </>
                        }
                    </Form>
                </Row>
                {errorMessage &&
                    <Alert variant="danger" style={{ width: '100%', textAlign: 'center' }}>
                        {errorMessage}
                    </Alert>
                }
            </Modal.Body>
            {validEmail &&
                <Modal.Footer>
                    {validEmail && !userExists && passwordsMatch &&
                        <button className="green-glow-button" onClick={setNewPassword}>
                            Submit New Password
                        </button>
                    }
                    {validEmail && !userExists && !passwordsMatch &&
                        <Alert variant="success" style={{ width: '100%', textAlign: 'center' }}>
                            Please input your desired password.
                        </Alert>
                    }
                    {validEmail && userExists &&
                        <button className="green-glow-button" onClick={login}>
                            Login
                        </button>
                    }
                </Modal.Footer>
            }
        </Modal>
    );
}

export default AuthModal;