import React, { useContext, useEffect, useState } from 'react';
import Alert from 'react-bootstrap/Alert';
import Col from 'react-bootstrap/esm/Col'
import Row from 'react-bootstrap/esm/Row'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faHandshake, faPersonMilitaryPointing, faPersonRunning, faPlus, faTicket } from '@fortawesome/free-solid-svg-icons';
import { Context } from '../../../../lib/Context';

const LiveElement = (props) => {
    const { handleShowMapCompetitorsModal, event, handleShowAddCompetitorsModal,
        handleShowAddAdjudicatorModal, handleShowEditAdjudicatorsModal, handleViewEvent
    } = props;
    const {
        type, setIsLiveEvent, events, usersID, setIsAdjudicator, isAdjudicator, setShowBuyPassModal,
        setShowBuyPassEventID
    } = useContext(Context);
    const [userQualified, setUserQualified] = useState(false);
    let eventID = event._id;


    const handleViewLiveEvent = () => {
        setIsLiveEvent(true);
        handleViewEvent(event._id);
    }

    useEffect(() => {
        for (let i = 0; i < events.length; i++) {
            if (events[i]._id === eventID) {
                for (let k = 0; k < events[i].adjudicators.length; k++) {
                    if (events[i].adjudicators[k].userID === usersID) {
                        setIsAdjudicator(true);
                    }
                }
                for (let j = 0; j < events[i].competitors.length; j++) {
                    if (events[i].competitors[j].userID === usersID) {
                        setUserQualified(true);
                    }
                }
            }
        }
    }, [eventID, events, setUserQualified, usersID, setIsAdjudicator]);

    const handleShowBuyPassModal = () => {
        setShowBuyPassModal(true);
        setShowBuyPassEventID(event._id);
        setIsLiveEvent(true); // Because we are in a virtual element
    }

    return (
        <div>
            {(type !== 'admin' && !isAdjudicator && !userQualified) &&
                <Row style={{ paddingLeft: '20px', paddingRight: '20px', paddingTop: '10px', paddingBottom: '0px' }}>
                    {event.liveFee > 0 ?
                        <Col>
                            <button className="green-glow-button-large" style={{
                                width: '100%', marginLeft: 'auto',
                                marginRight: 'auto', marginTop: '15px'
                            }}
                                onClick={() => { handleShowBuyPassModal() }}
                                variant="info" >
                                <FontAwesomeIcon icon={faTicket} /> Buy Event Pass
                            </button>
                        </Col>
                        :
                        <Col style={{ paddingLeft: '50px', paddingRight: '50px' }}>
                            <Alert variant={'danger'} style={{ marginBottom: '0px' }}>
                                This is a private, invite only event - if you want to take part then get in touch and we'll see what we can do!
                            </Alert>
                        </Col>
                    }
                </Row>
            }
            <Row style={{ paddingLeft: '20px', paddingRight: '20px', paddingTop: '0px', paddingBottom: '20px' }}>
                {type === 'admin' &&
                    <>
                        <Col xs={12} sm={6}>
                            <button className="green-glow-button-large" style={{
                                width: '100%', marginLeft: 'auto',
                                marginRight: 'auto', marginTop: '10px'
                            }}
                                variant="info" onClick={() => { handleShowAddCompetitorsModal(eventID) }}>
                                <FontAwesomeIcon icon={faPlus} /> Add Competitors
                            </button>
                            {event.competitors.length !== 0 &&
                                <button className="green-glow-button-large" style={{
                                    width: '100%', marginLeft: 'auto',
                                    marginRight: 'auto', marginTop: '10px'
                                }}
                                    variant="info" onClick={() => { handleShowMapCompetitorsModal({ eventID, type: "Live" }) }}>
                                    <FontAwesomeIcon icon={faPersonRunning} /> Edit Competitors
                                </button>
                            }
                        </Col>
                        <Col xs={12} sm={6}>
                            <button className="green-glow-button-large" style={{
                                width: '100%', marginLeft: 'auto',
                                marginRight: 'auto', marginTop: '10px'
                            }}
                                variant="info" onClick={() => { handleShowAddAdjudicatorModal(event._id) }}>
                                <FontAwesomeIcon icon={faPlus} /> Add Adjudicators
                            </button>
                            {event.adjudicators.length > 0 &&
                                <button className="green-glow-button-large" style={{
                                    width: '100%', marginLeft: 'auto',
                                    marginRight: 'auto', marginTop: '10px'
                                }}
                                    variant="info" onClick={() => { handleShowEditAdjudicatorsModal({ eventID, type: "Live" }) }}>
                                    <FontAwesomeIcon icon={faPersonMilitaryPointing} /> Edit Adjudicators
                                </button>
                            }
                        </Col>
                    </>
                }
                {((event.competitors.length > 0 && event.adjudicators.length > 0) || type === 'admin' || isAdjudicator || userQualified) &&
                    <Col xs={12}>
                        <button className="green-glow-button-large" style={{
                            width: '100%', marginLeft: 'auto',
                            marginRight: 'auto', marginTop: '10px'
                        }}
                            variant="info" onClick={handleViewLiveEvent}>
                            {isAdjudicator ?
                                <><FontAwesomeIcon icon={faHandshake} /> Update Scores</>
                                :
                                <><FontAwesomeIcon icon={faEye} /> View Leaderboard</>
                            }

                        </button>
                    </Col>
                }
            </Row>
        </div>
    )
}

export default LiveElement